import React from 'react';
import Logo from '../../components/Logo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';
import '../../../assets/styles/Web.scss';

const GdprRules = () => (
  <>
    <section className="Section Section--rules Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle"> Pravidla pro zpracování osobních údajů</h2>
        <p>
          Dokument „Pravidla pro zpracování osobních údajů“ jsme vytvořili, abychom zajistili
          bezpečnost a důvěrnost Vámi poskytnutých údajů naší společnosti SQN s.r.o. Níže naleznete
          informace o osobních údajích, které shromažďujeme, za jakým účelem a způsob jejich
          uchování.
        </p>
        <p>
          Společnost SQN s.r.o., se sídlem Kubelíkova 1224/42, 130 00 Praha 3, IČ 04965850, (dále
          jen „správce údajů”), bude zpracovávat poskytnuté (či z veřejných zdrojů získané) osobní
          údaje, a to v souladu s obecným nařízením o ochraně osobních údajů (EU) 2016/679.
        </p>
        <h3>1. Společnost SQN s.r.o. jako správce osobních údajů</h3>
        <p>
          Ve smyslu ust. § 5 odst. 2 zákona č. 101/200 Sb., o ochraně osobních údajů, ve znění
          pozdějších předpisů (dále též jako “zákon o ochraně osobních údajů”), a v souladu s čl. 6
          odst. 1. Písm. a) nařízení Evropského Parlamentu a Rady (EU) 2016/679 ze dne 27. dubna
          2016, o ochraně fyzických osob, v souvislosti se zpracováním osobních údajů a o volném
          pohybu těchto údajů, souhlasím se shromažďováním, uchováním a zpracováním mých osobních
          údajů mnou poskytnutých správci údajů.
        </p>
        <h3>2. Jaké informace budeme uchovávat?</h3>
        <p>
          SQN s.r.o. jakožto správce osobních údajů bude uchovávat a zpracovávat níže uvedené údaje.
          Tyto informace nemusí být u každého ze subjektu údajů stejné. Záleží na tom, kolik
          informací nám o sobě jednotlivý subjekt údajů poskytne v životopise či na profilu
          sociálních sítí (LinkedIN, Facebook atd.).
        </p>
        <ul>
          <li>název obchodní firmy/IČO/DIČ</li>
          <li>jméno a příjmení, akademický titul</li>
          <li>
            fotografie (pokud je součástí životopisu či prezentace na sociálních sítích, dále
            fotografie pořízené během vzdělávacích akcích pořádané společností SQN s.r.o.)
          </li>
          <li>datum a místo narození</li> <li> adresa trvalého bydliště, příp. kontaktní adresa</li>
          <li>kontakt (telefon, mail)</li>
          <li>pracovní zkušenosti, současné a předchozí zaměstnání</li>
          <li>dosažené vzdělání</li>
          <li>řidičské oprávnění</li>
          <li>znalost cizích jazyků</li>
          <li>odkaz na profil na sociálních sítích</li>
          <li>další informace poskytnuté v rámci zaslaných CV</li>
          <li>číslo bankovního účtu.</li>
        </ul>
        <h3>3. Za jakými účely budeme osobní údaje zpracovávat?</h3>
        <p>
          Vámi poskytnuté osobní údaje budeme shromažďovat, uchovávat a zpracovávat pro následující
          účely:
        </p>
        <ul>
          <li>
            osobní údaje poskytnuté subjektem údajů můžeme využít k zasílání komerčních a jiných
            sděleních (např. za účelem dalšího vzdělávání) týkající se poskytovaných služeb
            společnosti SQN s.r.o., a to prostřednictvím emailu, nebo sociálních sítí.
          </li>
          <li>
            zasílání nabídek na účast v IT projektech na základě požadavků subjektů údajů či
            vhodnosti daného profilu
          </li>
          <li>
            pro poskytnutí třetí osobě, společnosti Google LLC se sídlem na adrese 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, Spojené státy americké, která poskytuje cloudové
            služby uložení a zabezpečení dat,
          </li>
          <li>
            pro poskytnutí třetí osobě, jinému zpracovateli, za účelem uzavření obchodního případu.
            K tomu, aby mohly být osobní údaje předány dalšímu zpracovateli, musí správce údajů
            obdržet od subjektu údajů souhlas.
          </li>
          <li>
            pokud někdo předá vaše osobní údaje společnosti SQN s.r.o., musí Vás o tom rovněž předem
            informovat,
          </li>
          <li>
            poskytujete-li společnosti SQN s.r.o. osobní údaje třetích osob, je Vaší povinností
            informovat o tom dotyčnou osobu a zajistit si její souhlas s těmito podmínkami ochrany
            osobních údajů.
          </li>
        </ul>
        <h3>4. Jak dlouho budeme Vaše osobní údaje zpracovávat?</h3>
        <p>
          Osobní údaje budeme zpracovávat po dobu nezbytně nutnou vzhledem k účelu, za jakým údaje
          zpracováváme. Pokud neodvoláte souhlas, budeme osobní údaje zpracovávat nejdéle po dobu 5
          let od okamžiku, kdy nám byl subjektem údajů udělen souhlas se zpracováním osobních údajů.
        </p>
        <h3>5. Jakým způsobem budou osobní údaje zpracovány a uchovány?</h3>
        <p>Osobní údaje budou zpracovány:</p>
        <ul>
          <li>strojově (automatizovaně) prostřednictvím počítačů a počítačových programů</li>
          <li>v písemné podobě.</li>
        </ul>
        <h3>6. Práva subjektu údajů, informace a přístup k osobním údajům</h3>
        <p>
          Jako subjekt údajů mám právo na přístup k mým osobním údajům, mám právo na jejich opravu
          nebo výmaz, popřípadě omezení zpracování. Mám právo požadovat informaci, jaké osobní údaje
          jsou zpracovány a zároveň práva požadovat vysvětlení ohledně zpracování osobních údajů.
        </p>
        <p>
          Dále mám právo vznést námitku proti zpracování, jakož i práva na přenositelnost údajů (tj.
          právo získat osobní údaje, které se mne týkají, které jsem poskytl správci údajů ve
          strukturovaném, běžně používaném a strojově čitelném formátu, blíže viz. čl. 20 GDPR).
        </p>
        <p>
          Mám právo souhlas kdykoliv odvolat, aniž by tím byla dotčena zákonnost zpracování založená
          na souhlasu uděleném před jeho odvoláním, pokud je zpracování založeno na čl. 6 odst. 1
          písm. a) nebo čl. 9 odst. 2 písm. a) GDPR. To znamená, že takové právo nemám zejména
          tehdy, pokud je zpracování nezbytné pro splnění právní povinnosti, která se na správce
          údajů vztahuje.
        </p>
        <p>
          Jako subjekt údajů jsem informován o tom, že souhlas k uchování a zpracování údajů mohu
          odvolat odesláním e-mailu na adresu info@sqn.cz. Mám právo kdykoliv si zvolit, že nechci
          dostávat komerční sdělení. Můžu tak učinit taktéž zasláním emailu na adresu info@sqn.cz.
        </p>
        <p>
          Správce údajů prohlašuje, že při zpracování nedochází k automatizovanému rozhodování,
          včetně profilování, uvedeném v čl. 22 odst. 1 a 4 GDPR.
        </p>
        <p>
          Správce prohlašuje, že osobní údaje nejsou zpracovány pro účely vědeckého či historického
          výzkumu nebo pro statistické účely.
        </p>
        <h3>7. Závěrečná ustanovení</h3>
        <p>
          Jako subjekt údajů prohlašuji, že jsem si vědom(a) svých práv podle ust. § 12 a § 21
          zákona o ochraně osobních údajů a svých práv podle GDPR.
        </p>
        <p>
          Prohlašuji, že všechny poskytnuté údaje jsou přesné a pravdivé a jsou poskytovány
          dobrovolně.
        </p>
        <p>
          Správce údajů prohlašuje, že bude shromažďovat osobní údaje v rozsahu nezbytném pro
          naplnění účelu a zpracovávat je bude pouze v souladu s účelem, k němuž byly shromážděny.
        </p>
        <p>
          Tento souhlas je svobodný a vědomý projev vůle subjektu údajů, jehož obsahem je svolení
          subjektu údajů se zpracováním osobních údajů.
        </p>
      </div>
    </section>
    <footer className="Footer">
      <Contact />
    </footer>
  </>
);

export default withIntl(GdprRules);
